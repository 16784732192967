import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import RouteViewComponent from '../layouts/RouterBypass.vue'

const isAuthenticated = () => {
  return localStorage.getItem('auth') === 'true'
}

/* const generateSitemap = (routes: Array<RouteRecordRaw>, parentPath = ''): string => {
  let sitemap = ''
  for (const route of routes) {
    const fullPath = `${parentPath}${route.path}`.replace(/\/{2,}/g, '/')
    console.log(`Procesando ruta: ${fullPath}`) // Verificar las rutas procesadas
    sitemap += `- ${fullPath}\n`
    if (route.children && route.children.length > 0) {
      sitemap += generateSitemap(route.children, `${fullPath}/`)
    }
  }
  return sitemap
} */

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'clientes',
        path: 'clientes',
        component: () => import('../pages/clientes/ClientesPage.vue'),
      },
      {
        name: 'sectores',
        path: 'sectores',
        component: () => import('../pages/sectores/SectoresPage.vue'),
      },
      {
        name: 'empleados',
        path: 'empleados',
        component: () => import('../pages/empleados/EmpleadosPage.vue'),
      },
      {
        name: 'tipoCliente',
        path: 'tipoCliente',
        component: () => import('../pages/tipoClientes/TipoClientesPage.vue'),
      },
      {
        name: 'instituciones',
        path: 'instituciones',
        component: () => import('../pages/instituciones/InstitucionesPage.vue'),
      },
      {
        name: 'valores',
        path: 'valores',
        component: () => import('../pages/valores/ValoresPage.vue'),
      },
      {
        name: 'basico',
        path: 'basico',
        component: () => import('../pages/basicos/BasicosPage.vue'),
      },
      {
        name: 'pago',
        path: 'pago',
        component: () => import('../pages/pagos/PagosPage.vue'),
      },

      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'medidores',
        path: 'meters',
        component: () => import('../pages/medidores/MedidoresPage.vue'),
      },
      {
        name: 'Factura',
        path: 'factura',
        component: () => import('../pages/facturas/FacturasPage.vue'),
      },
      {
        name: 'medidor',
        path: 'meter',
        component: () => import('../pages/medidores/MedidoresPage.vue'),
      },
      {
        name: 'Reportes',
        path: 'reportes',
        component: () => import('../pages/medidores/MedidoresPage.vue'),
      },
      {
        name: 'Pago del agua',
        path: 'repor_pago_del_agua',
        component: () => import('../pages/reportes/PagoAguaPage.vue'),
      },
      {
        name: 'Clientes',
        path: 'repor_cliente',
        component: () => import('../pages/reportes/ClientesPage.vue'),
      },
      {
        name: 'Consulta Documentos',
        path: 'consultaDocumentos',
        component: () => import('../pages/ConsultaDocumentos/ConsultaDocumentosPage.vue'),
      },
      {
        name: 'Cuentas Por Cobrar',
        path: 'cuentasPorCobrar',
        component: () => import('../pages/medidores/MedidoresPage.vue'),
      },
      {
        name: 'Facturar Cuentas',
        path: 'facturarCuentas',
        component: () => import('../pages/facturaCuentas/FacturarCuentasPage.vue'),
      },
      {
        name: 'Clientes Medidor',
        path: 'reporte-cliente-medidor',
        component: () => import('../pages/reportes/ClientesMedidorPage.vue'),
      },
      {
        name: 'ingresoMedidor',
        path: 'ingresoMedidor',
        component: () => import('../pages/medidores/MedidoresPage.vue'),
      },
      {
        name: 'reinstalacion',
        path: 'reinstalacion',
        component: () => import('../pages/medidores/MedidoresPage.vue'),
      },
      {
        name: 'Facturar Agua',
        path: 'facturarConsumo',
        component: () => import('../pages/facturaMedidor/FacturaMedidorPage.vue'),
      },
      {
        name: 'Modulo Roles y Usuario',
        path: 'usuarios',
        //component: () => import('../pages/facturaMedidor/FacturaMedidorPage.vue'),
        component: () => import('../pages/usuarios/UsuariosPage.vue'),
      },
      {
        name: 'Configuración Puntos Emisión',
        path: 'instituciones',
        component: () => import('../pages/instituciones/InstitucionesPage.vue'),
      },
      {
        name: 'Configuración Empresa',
        path: 'instituciones',
        component: () => import('../pages/instituciones/InstitucionesPage.vue'),
      },
      {
        name: 'Subir Firma Digital',
        path: 'instituciones',
        component: () => import('../pages/instituciones/InstitucionesPage.vue'),
      },
      {
        name: 'Todos',
        path: 'repor_todos',
        component: () => import('../pages/reportes/TodosPage.vue'),
      },
      {
        name: 'Seguridad',
        path: 'seguridad',
        component: () => import('../pages/seguridad/SeguridadPage.vue'),
      },
      {
        name: 'Encriptacion',
        path: 'seguridad',
        component: () => import('../pages/seguridad/SeguridadPage.vue'),
      },
      {
        name: 'Medidor por Instalar',
        path: 'reporte-medidor-instalar',
        component: () => import('../pages/reportes/MedidorPorInstalarPage.vue'),
      },
      {
        name: 'Medidores Instalados',
        path: 'reporte-medidores-instalados',
        component: () => import('../pages/reportes/MedidoresInstaladosPage.vue'),
      },
      {
        name: 'Derechos de Agua',
        path: 'reporte-derechos-agua',
        component: () => import('../pages/reportes/DerechoAguaPage.vue'),
      },
      {
        name: 'Ingresos Agua',
        path: 'reporte-ingreso-agua',
        component: () => import('../pages/reportes/IngresoAguaPage.vue'),
      },
      {
        name: 'Ingresos otros',
        path: 'reporte-ingresos-otros',
        component: () => import('../pages/reportes/IngresosOtrosPage.vue'),
      },
      {
        name: 'Ingresos Total',
        path: 'reporte-ingresos-total',
        component: () => import('../pages/reportes/IngresosTotalPage.vue'),
      },
      {
        name: 'Reporte de personas morosas',
        path: 'reporte-personas-morosas',
        component: () => import('../pages/reportes/PersonasMorosasPage.vue'),
      },
      {
        name: 'Reporte INACTIVO SUSPENDIDO',
        path: 'reporte-inactivo-suspendido',
        component: () => import('../pages/reportes/InactivoSuspendidoPage.vue'),
      },
      {
        name: 'Clientes Deudas',
        path: 'clientesDeudas',
        component: () => import('../pages/clientesDeudas/ClientesDeudasPage.vue'),
      },
      {
        name: 'Clientes Deudas Fechas',
        path: 'Encriptacion',
        component: () => import('../pages/ConsultaDocumentos/ConsultaDocumentosPage.vue'),
      },
      {
        name: 'Roles',
        path: 'roles',
        component: () => import('../pages/roles/RolesPage.vue'),
      },
      {
        name: 'payments',
        path: '/payments',
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/payments/PaymentsPage.vue'),
            children: [
              {
                name: 'transaction-history',
                path: 'transaction-history',
                component: () => import('../pages/payments/PaymentsPage.vue'),
              },
            ],
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
    ],
    beforeEnter: (to, from, next) => {
      if (!isAuthenticated()) {
        next({ name: 'login' })
      } else {
        next()
      }
    },
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'login' },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

/* console.log('Sitemap generado:\n' + generateSitemap(routes))
const sitemap = generateSitemap(routes)
const blob = new Blob([sitemap], { type: 'text/plain;charset=utf-8' })
saveAs(blob, 'sitemap.txt') */

// Global navigation guard to check authentication
router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !isAuthenticated()) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
